// Generated by Framer (e1877f1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["JqXGCyfhi"];

const serializationHash = "framer-C893Y"

const variantClassNames = {JqXGCyfhi: "framer-v-nmhj4c"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, width, ...props}) => { return {...props, EDXgslTl0: link ?? props.EDXgslTl0} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, EDXgslTl0, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "JqXGCyfhi", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={EDXgslTl0} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-nmhj4c", className, classNames)} framer-1djuv80`} data-framer-name={"Ready"} layoutDependency={layoutDependency} layoutId={"JqXGCyfhi"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}/></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-C893Y.framer-1djuv80, .framer-C893Y .framer-1djuv80 { display: block; }", ".framer-C893Y.framer-nmhj4c { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 32px; height: min-content; justify-content: center; min-height: 278px; overflow: hidden; padding: 16px 0px 16px 0px; position: relative; text-decoration: none; width: 960px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-C893Y.framer-nmhj4c { gap: 0px; } .framer-C893Y.framer-nmhj4c > * { margin: 0px; margin-left: calc(32px / 2); margin-right: calc(32px / 2); } .framer-C893Y.framer-nmhj4c > :first-child { margin-left: 0px; } .framer-C893Y.framer-nmhj4c > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 278
 * @framerIntrinsicWidth 960
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"EDXgslTl0":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerHlABfQs5O: React.ComponentType<Props> = withCSS(Component, css, "framer-C893Y") as typeof Component;
export default FramerHlABfQs5O;

FramerHlABfQs5O.displayName = "Article Cards";

FramerHlABfQs5O.defaultProps = {height: 278, width: 960};

addPropertyControls(FramerHlABfQs5O, {EDXgslTl0: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerHlABfQs5O, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})